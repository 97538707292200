import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "M-Auto D-Flex",
  style: {"gap":"4em","flex-direction":"column"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vueQr = _resolveComponent("vueQr")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["D-Flex W-100 BigBody", _ctx.FillBodyHeight]),
    style: {"gap":"1em","flex-direction":"column"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.$store.getters.DynamicConfig.Logo,
        class: "M-Auto Shadow2",
        style: {"max-width":"50vw","max-height":"40vh"},
        alt: ""
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        class: "color3 O-50 F-Bold",
        style: {"font-size":"min(3em, 4vw)"},
        innerHTML: _ctx.$store.getters.DynamicConfig?.Name?.replaceAll('\n', '<br>')
      }, null, 8, _hoisted_3),
      _createVNode(_component_vueQr, {
        style: {"width":"10em"},
        text: _ctx.QRcode,
        class: "Rad1 M-Auto",
        size: 500,
        dotScale: 1
      }, null, 8, ["text"])
    ])
  ], 2))
}